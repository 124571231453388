import React, { useState, useEffect } from 'react';
import './Sidebar.scss';
// icons

import {
  Box,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import benvo from '../../assets/benvo-txt.svg';
import benvoIcon from '../../assets/benvo-icon.png';
import { clearToken, getTokenInfo } from '../../utils/configAuth';
import { isSuperAdmin, getFeatures, isAdmin } from '../../utils/checkUser';
import PATHS from '../../constants/applicationPaths';
import ShopIcon from '../../assets/icons/ShopIcon';
import Menu from './components/Menu';
import HomeIcon from '../../assets/icons/homeIcon';
import ShopIconActive from '../../assets/icons/ShopIconActive';
import HomeIconActive from '../../assets/icons/HomeIconActive';
import MenuLogout from './components/MenuLogout';
import UsersIcon from '../../assets/icons/UsersIcons';
import TasksIcon from '../../assets/icons/TasksIcon';
import MedicalIcon from '../../assets/icons/MedicalIcon';
import ListIcon from '../../assets/icons/ListIcon';
import RegisterIconActive from '../../assets/icons/RegisterIconActive';
import RegisterIcon from '../../assets/icons/RegisterIcon';
import OrderIcon from '../../assets/icons/OrderIcon';
import ResidentsIcon from '../../assets/icons/ResidentsIcon';
import HealthIcon from '../../assets/icons/HealthServiceIcon';
import routes from '../../services/routes';
import { StyledDrawer, StyledDrawerButton } from './styles';

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const path = useLocation();
  const navigate = useNavigate();

  const userType = getTokenInfo('type');

  const verifyReadTerms = async () => {
    const ilpiId = getTokenInfo('ilpiId');
    try {
      const {
        message: { readTermsOn },
      } = await routes.ilpi.getIlpiById({ ilpiId });
      if (!readTermsOn && isAdmin()) return navigate(PATHS.terms);
      return false;
    } catch (error) {
      clearToken();
      return navigate('/login');
    }
  };

  useEffect(() => {
    verifyReadTerms();
  }, []);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const itemMenu = [
    {
      title: 'Início',
      icon:
        path.pathname.startsWith(PATHS.superadmin.home) || path.pathname.startsWith(PATHS.home) ? (
          <HomeIconActive />
        ) : (
          <HomeIcon />
        ),
      link: isSuperAdmin() ? PATHS.superadmin.home : PATHS.home,
      access: true,
      isDrop: false,
      validUser: true,
    },
    {
      title: 'Residentes',
      icon: <ResidentsIcon active={path.pathname.includes(PATHS.residents.root)} />,
      isDrop: false,
      validUser: isAdmin(),
      access: true,
      link: PATHS.residents.root,
    },
    {
      title: 'Exames',
      icon: <HealthIcon active={path.pathname.includes(PATHS.exams.root)} />,
      isDrop: false,
      validUser: isAdmin(),
      access: true,
      link: getFeatures('healthServices') ? PATHS.exams.root : 'https://wa.me/message/RCJ57E3GBZUHP1',
    },
    {
      title: 'Saúde',
      icon: <MedicalIcon />,
      isDrop: true,
      validUser: false,
      itens: [
        {
          title: 'Prescrições',
          link: PATHS.prescriptions,
          icon: {},
          validUser: true,
        },
        {
          title: 'Receitas',
          link: PATHS.recipes,
          icon: {},
          validUser: true,
        },
        {
          title: 'Evoluções',
          link: PATHS.evolutions,
          icon: {},
          validUser: true,
        },
        {
          title: 'Agenda',
          icon: {},
          isDrop: false,
          link: PATHS.schedule,
          validUser: false,
        },
        {
          title: 'Hospedagem',
          icon: {},
          isDrop: false,
          link: PATHS.accommodations,
          validUser: true,
        },
      ],
    },
    {
      title: 'Profissionais',
      icon: <UsersIcon active={path.pathname.includes(PATHS.collaborators)} />,
      isDrop: false,
      validUser: isAdmin(),
      access: true,
      link: PATHS.collaborators,
    },
    {
      title: 'Lista Mensal',
      icon: <ListIcon />,
      link: PATHS.shoppingList,
      validUser: false,
    },
    {
      title: 'Tarefas',
      icon: <TasksIcon />,
      isDrop: true,
      validUser: false,
      itens: [
        {
          title: 'Gestão de Tarefas',
          link: PATHS.tasks,
          icon: {},
          validUser: true,
        },
        {
          title: 'Registro de Tarefas',
          link: PATHS.tasksRegistry,
          icon: {},
          validUser: true,
        },
      ],
    },
    {
      title: 'Sistema de Compras',
      icon:
        path.pathname.startsWith(PATHS.marketplace) || path.pathname.startsWith(PATHS.superadmin.marketplace) ? (
          <ShopIconActive />
        ) : (
          <ShopIcon />
        ),
      validUser: isAdmin() || isSuperAdmin(),
      isDrop: false,
      access: getFeatures('marketplace'),
      link: isSuperAdmin() ? PATHS.superadmin.marketplace : PATHS.marketplace,
    },
    {
      title: 'Pedidos',
      icon: <OrderIcon active={path.pathname.includes(PATHS.orders)} />,
      validUser: isAdmin(),
      access: getFeatures('marketplace'),
      isDrop: false,
      link: PATHS.orders,
    },
    {
      title: 'Cadastros',
      icon:
        path.pathname.startsWith(PATHS.superadmin.ilpis) || path.pathname.startsWith(PATHS.superadmin.sellers) ? (
          <RegisterIconActive />
        ) : (
          <RegisterIcon />
        ),
      isDrop: true,
      validUser: isSuperAdmin(),
      itens: [
        {
          title: 'Cadastro de ILPI',
          link: PATHS.superadmin.ilpis,
          icon: {},
          validUser: isSuperAdmin(),
          access: true,
        },
        {
          title: 'Cadastro de Fornecedores',
          link: PATHS.superadmin.sellers,
          icon: {},
          validUser: isSuperAdmin(),
          access: true,
        },
        {
          title: 'Cadastro de Funcionários',
          link: PATHS.employees,
          icon: {},
          validUser: false,
        },
      ],
    },
  ];

  if (userType === 'caregiver') return '';

  return (
    <StyledDrawer variant="permanent" hideBackdrop open={isOpen} anchor="left">
      <StyledDrawerButton
        size="small"
        onClick={toggleDrawer}
        sx={{
          left: isOpen ? '249px' : '95px',
        }}
      >
        {isOpen ? (
          <KeyboardArrowLeft
            sx={{
              width: '15px',
              height: '15px',
              color: '#ffffff',
            }}
          />
        ) : (
          <KeyboardArrowRight
            sx={{
              width: '15px',
              height: '15px',
              color: '#ffffff',
            }}
          />
        )}
      </StyledDrawerButton>
      <Box
        sx={{
          overflow: 'hidden',
          width: isOpen ? '156px' : '40px',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '22px',
            width: '156px',
          }}
        >
          <img src={benvoIcon} alt="benvo-logo" style={{ width: '40px', height: '40px' }} />
          <img src={benvo} alt="benvo-logo" />
        </Box>
      </Box>
      <Box
        sx={{
          mt: '106px',
        }}
      >
        <Menu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          menuList={itemMenu}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
      </Box>
      <MenuLogout isOpen={isOpen} />
    </StyledDrawer>
  );
}

export default Sidebar;
