import React from 'react';
import { useNavigate } from 'react-router';
import {
  Button, Card, DesktopImage,
} from './style';
import ExamsImageDesktop from '../../../../assets/home/exams-desktop.webp';
import { getFeatures } from '../../../../utils/checkUser';
import applicationPaths from '../../../../constants/applicationPaths';

export default function RequestExamsCard() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    const hasFeature = getFeatures('healthServices');

    console.log(hasFeature);
    if (hasFeature === 0) {
      window.open('https://wa.me/message/RCJ57E3GBZUHP1', '_blank');
    } else {
      navigate(applicationPaths.exams.root);
    }
  };
  return (
    <Card>
      <DesktopImage src={ExamsImageDesktop} alt="Solicitação de exames" />
      <h2>Solicitação de exames domiciliares em segundos</h2>
      <p>Abandone o telefone para agendar e receber resultados de Análises Clínicas e Imagem.</p>

      {/* <Button type="button" onClick={() => window.open(applicationPaths.exams.root)}>Fazer pedido</Button> */}
      <Button type="button" onClick={handleNavigate}>Fazer pedido</Button>
    </Card>
  );
}
